<template>
  <v-card
    outlined
    class="pa-5 mt-4 no-focus"
  >
    <div class="d-flex align-center">
      <div class="pb-0 d-flex w-full flex-md-row flex-column">
        <router-link
          to="/content-creator"
          class="text-decoration-none"
        >
          <div class="d-flex align-center flex-grow-0 mr-4">
            <v-icon
              class="static-gradient-background rounded-circle pa-2 mr-4"
              color="white"
              x-large
            >
              mdi-share-outline
            </v-icon>
            <span class="title headline grey--text font-weight-light">{{ $t('labels.statistics.content-creator') }}</span>
          </div>
        </router-link>

        <div class="flex-grow-1 d-flex align-center justify-space-around flex-column flex-md-row mt-4 mt-md-0">
          <StatisticValue
            :loading="!savedContents"
            :title="$t('content-creator.dashboard.contents')"
            :value="savedContents?.total"
          />
          <StatisticValue
            :loading="!savedContents"
            :title="$t('content-creator.dashboard.contact-points')"
            :value="savedContents?.numberOfPosts"
          />
          <StatisticValue
            :loading="!savedContents"
            :title="$t('content-creator.dashboard.time')"
            :value="timeSaved"
          />
          <StatisticValue
            :loading="!savedContents"
            :title="$t('content-creator.dashboard.money')"
            :value="moneySaved"
          />
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import feature from '@/mixins/feature'
import currency from '@/mixins/currency'
import GET_SAVED_CONTENTS from './getSavedContents.gql'
import StatisticValue from './StatisticValue.vue'
const TIME_MULTIPLIER = 45
const MONEY_MULTIPLIER = 150

export default {
  components: {
    StatisticValue
  },
  mixins: [feature, currency],
  computed: {
    timeSaved () {
      return this.savedContents?.total && this.formatTime(this.savedContents?.total * TIME_MULTIPLIER)
    },
    moneySaved () {
      return this.savedContents?.total && this.formatPrice(this.savedContents?.total * MONEY_MULTIPLIER)
    }
  },
  methods: {
    formatTime (minutes) {
      return `${Math.round(minutes / 60)} h`
    }
  },
  apollo: {
    savedContents: {
      query: GET_SAVED_CONTENTS,
      variables () {
        return {
          input: {
            perPage: 1,
            page: 1
          }
        }
      }
    }
  }
}
</script>
